@font-face {
    font-family: "Gotham";
    src: url("/assets/fonts/Gotham/Gotham-Black.eot");
    src: url("/assets/fonts/Gotham/Gotham-Black.eot?#iefix")
        format("embedded-opentype"),
      url("/assets/fonts/Gotham/Gotham-Black.woff2") format("woff2"),
      url("/assets/fonts/Gotham/Gotham-Black.woff") format("woff"),
      url("/assets/fonts/Gotham/Gotham-Black.ttf") format("truetype"),
      url("/assets/fonts/Gotham/Gotham-Black.svg#Gotham-Black") format("svg");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Gotham";
    src: url("/assets/fonts/Gotham/assets/fonts/fonts/Gotham/Gotham-Bold.eot");
    src: url("/assets/fonts/Gotham/Gotham-Bold.eot?#iefix")
        format("embedded-opentype"),
      url("/assets/fonts/Gotham/Gotham-Bold.woff2") format("woff2"),
      url("/assets/fonts/Gotham/Gotham-Bold.woff") format("woff"),
      url("/assets/fonts/Gotham/Gotham-Bold.ttf") format("truetype"),
      url("/assets/fonts/Gotham/Gotham-Bold.svg#Gotham-Bold") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: "Gotham";
    src: url("/assets/fonts/Gotham/Gotham-Book.eot");
    src: url("/assets/fonts/Gotham/Gotham-Book.eot?#iefix")
        format("embedded-opentype"),
      url("/assets/fonts/Gotham/Gotham-Book.woff2") format("woff2"),
      url("/assets/fonts/Gotham/Gotham-Book.woff") format("woff"),
      url("/assets/fonts/Gotham/Gotham-Book.ttf") format("truetype"),
      url("/assets/fonts/Gotham/Gotham-Book.svg#Gotham-Book") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }