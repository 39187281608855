@use './global' as g;
@import './fonts.css';

$track: #fff;
// $thumb: g.$k-azul-500;
$thumb: #bababa;
* {
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  outline: none;
  margin: 0;
  padding: 0;

  scrollbar-width: auto;
  scrollbar-color: $thumb $track;

  &:hover {
    scrollbar-color: $thumb $track;
  }
}

*::-webkit-scrollbar {
  width: 14px;
}

*::-webkit-scrollbar-track {
  background: $track;
}

*::-webkit-scrollbar-thumb {
  background-color: $thumb;
  border-radius: 10rem;
  border: 3px solid $track;

  &:hover {
    background-color: darken($thumb, 5);
  }
}

.fa,
.fas {
  font-family: 'Font Awesome 5 Free';
}

html,
body {
  background-color: #fff;
  font-size: 16px;

  @include g.media-down(md) {
    font-size: 14px;
  }

  @include g.media-down(sm) {
    font-size: 15px;
  }
}

a {
  color: g.$k-cinza-900;

  &:hover {
    color: g.$k-cinza-500;
  }
}

.wrapper {
  margin: 3rem 0 5rem;
  padding: 2rem;
  position: relative;
  z-index: 1;
  background-color: #fff;

  @include g.media-down(sm) {
    padding: 0;
  }
}

.full-label input {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

::placeholder {
  color: #aaa;
  opacity: 1;
}

::selection {
  background-color: rgba(g.$k-azul-500, 0.8);
  color: #fff;
}

.version {
  font-size: 0.7rem;
  text-align: right;
  color: #aaa;
  margin: 0;
  position: fixed;
  bottom: 0.3rem;
  left: 1rem;
  z-index: 0;
}

// NG-PRIME

// Input
.p-inputtext:enabled {
  outline: 0 none;
  outline-offset: 0;

  &:focus {
    box-shadow: 0 0 0 0.15rem rgba(g.$rosa-site, 0.5);
    border-color: g.$rosa-site;
  }

  &:hover {
    border-color: g.$rosa-site;
  }
}

.p-disabled,
.p-component:disabled {
  background-color: #f5f5f5;
}

// Checkbox
.form-check {
  .form-check-input {
    width: 1.3rem;
    height: 1.3rem;
    cursor: pointer;
    margin-top: 0.05rem;

    &:checked {
      border-color: g.$rosa-site;
      background-color: g.$rosa-site;
    }

    &:focus {
      border-color: g.$rosa-site;
      box-shadow: 0 0 0 0.2rem rgb(g.$rosa-site, 0.2);
    }
  }

  .form-check-label {
    margin-left: 0.6rem;
    cursor: pointer;
  }
}
